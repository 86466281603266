@import url("./modal.css");
@import url("./icon.css");

* {
    margin: 0px;
    padding: 0;
}

html {
    font-size: 20px;
    font-family: "Source Sans", sans-serif;
    font-weight: 200;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    color: white;
}

body {
    background: url(../img/coverimg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body:after {
    content: "";
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -5; /* needed to keep in the background */
    background: url(../img/coverimg.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.hamburger {
    display: none;
}

.nav-bar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    margin: 1rem auto;
    max-width: 50rem;
}

.nav-bar ul {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.nav-bar li {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    list-style-type: none;
    color: white;
    letter-spacing: 0.05rem;
}

.nav-bar a {
    text-decoration: none;
}

.nav-bar a li:hover,
.nav-bar a li:active {
    color: rgb(223, 223, 223);
}

.welcome-message-container {
    display: grid;
    justify-content: center;
    grid-gap: 1rem;
    margin-top: 15rem;
    margin-bottom: 15rem;
}

.small-font {
    font-size: 30px;
}

.large-font {
    font-size: 100px;
    letter-spacing: 0.5rem;
}

.content-strip {
    display: block;
    background: rgb(234, 234, 234);
}

.paragraph {
    font-size: 15px;
    font-family: "Source Sans Pro";
    color: rgb(0, 9, 59);
    grid-column: 2 / span 1;
}

.description-container {
    max-width: 50rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21.5rem, 1fr));
    grid-gap: 1rem;
    padding: 1rem 0;
    color: rgb(0, 9, 59);
}

.short-description {
    font-size: 45px;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
}

.long-description p {
    font-size: 18px;
    font-family: "Source Sans Pro";
    margin: 0 1.5rem 1rem 1.5rem;
    line-height: 1rem;
}

.page-name {
    font-size: 25px;
    letter-spacing: 0.1rem;
}

.people-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21.5rem, 1fr));
    grid-gap: 1rem;
    max-width: 50rem;
    margin: 0 auto;
    padding: 1rem 0;
}

.mini-description {
    font-size: 25px;
    max-width: 50rem;
    margin: 0 auto;
    text-align: center;
    grid-column: span 2;
    color: rgb(0, 9, 59);
    padding: 3rem 0 2rem 0;
}

.box {
    display: grid;
    grid-template-columns: 9rem 1fr;
    grid-gap: 0.5rem;
    background: rgb(246, 248, 249);
    color: rgb(0, 9, 59);
}

.image {
    padding-left: 0.5rem;
    padding-top: 0.5rem;
}

.image img {
    border-radius: 50%;
}

.name {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    font-weight: 800;
}

.role {
    font-family: "Source Sans Pro";
    font-weight: 500;
    padding-bottom: 1rem;
}

.bio {
    font-size: 18px;
    font-family: "Source Sans Pro";
    font-weight: 300;
    margin-bottom: 1rem;
    line-height: 1.25rem;
    padding-right: 0.5rem;
}

.project-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    max-width: 50rem;
    margin: 0 auto;
    grid-gap: 1rem;
    padding: 1rem 0;
}

.project-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 2fr 1fr;
    grid-gap: 0.5rem;
    background: rgb(246, 248, 249);
    color: rgb(0, 9, 59);
    text-align: center;
}

.project-name {
    font-size: 30px;
    font-family: "Source Sans Pro";
    font-weight: 500;
    padding: 1.5rem 1rem 1rem 1rem;
}

.project-description {
    font-family: "Source Sans Pro";
    font-weight: 300;
    padding-left: 1rem;
    padding-right: 1rem;
}

.paragraph-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1rem;
    padding-bottom: 1rem;
}

.workshop-resources {
    padding-left: 1rem;
}

.publication-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1rem;
    padding-bottom: 1rem;
}

.year {
    font-size: 25px;
    grid-column: 2 / span end;
    color: rgb(0, 9, 59);
    padding: 1rem 0px 0px 0px;
}

.article {
    font-size: 15px;
    font-family: "Source Sans Pro";
    color: rgb(0, 9, 59);
    grid-column: 2 / span 1;
}

.lab-author {
    font-weight: bold;
}

.italics {
    font-style: italic;
}

.publication-link {
    word-wrap: break-word;
    color: rgb(0, 9, 59);
}

.footer {
    display: grid;
    grid-template-columns: 1fr;
    height: 5rem;
}

.footer p {
    font-size: 15px;
    font-family: "Source Sans Pro";
    margin: auto;
}

@media (max-width: 900px) {
    .html {
        max-width: 25rem;
    }

    .welcome-message-container {
        display: grid;
        justify-content: center;
        margin: 5rem;
    }

    .small-font {
        font-size: 30px;
        max-width: 25rem;
    }

    .large-font {
        font-size: 65px;
    }

    .description-container {
        grid-template-columns: 1fr;
        margin: 0 auto;
        padding-right: 1rem;
        padding-left: 0.5rem;
    }

    .short-description {
        font-size: 35px;
    }

    .page-name {
        font-size: 15px;
        letter-spacing: 0.1rem;
        text-align: center;
    }

    .people-container {
        grid-template-columns: 1fr;
    }

    .box {
        grid-template-columns: 1fr;
        justify-items: center;
        min-width: 100%;
    }

    .image {
        padding-top: 1rem;
    }

    .external-links {
        font-size: 1.2rem;
    }

    .alumni-external-links {
        font-size: 1.2rem;
    }

    .svg-icon svg {
        width: 1.5rem;
    }

    .name {
        text-align: center;
    }

    .role {
        text-align: center;
    }

    .bio {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .alumni-external-links {
        text-align: center;
    }

    .modal-modal img {
        max-width: 90vw;
    }
}

@media (max-width: 700px) {
    .html {
        background: url(../img/coverimg.jpg) no-repeat center center scroll;
    }

    .nav-bar-hide {
        display: none;
    }
    .hamburger {
        display: block;
        width: 2rem;
        position: fixed;
        right: 0.5rem;
        top: 0;
        margin-left: auto;
    }

    .hamburger svg path {
        fill: white;
    }
}
