.icon {
    color: rgb(0, 9, 59);
}

.ion-icon {
    color: rgb(0, 9, 59);
}

.svg-icon svg {
    width: 1rem;
}

.svg-icon svg path {
    fill: rgb(0, 9, 59);
}

.external-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1rem, 1rem));
    text-align: center;
    justify-content: space-evenly;
}

.external-links a {
    text-decoration: none;
}

.alumni-external-links {
    text-align: left;
}

.alumni-external-links a {
    padding: 0 0.25rem;
    text-decoration: none;
}
