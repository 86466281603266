.display-modal {
    display: block;
}

.modal-enabler {
    position: relative;
}
.hide-modal {
    display: none;
}

.modal-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../img/backgroundimg.jpg) no-repeat center center fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    z-index: 25;
}

.modal-background:after {
    content: "";
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -5; /*needed to keep in the background */
    background: url(../img/backgroundimg.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.modal-modal {
    max-width: 40rem;
    max-height: 80%;
    overflow: auto;
    margin: 0 auto;
    background: black;
    color: white;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-weight: 300;
    padding: 1rem;
}

.modal-modal h2 {
    padding-bottom: 1rem;
    max-width: 30rem;
}

.modal-modal p {
    padding-bottom: 1rem;
    text-align: left;
    max-width: 30rem;
}

.modal-modal img {
    max-width: 30rem;
}
.close-modal-button {
    width: 7.5rem;
    padding: 0.5rem;
    font-size: 15px;
}

.button {
    font-size: 15px;
    font-family: "Source Sans Pro";
    font-weight: 300;
    color: black;
}

.cross {
    display: block;
    width: 2rem;
    position: fixed;
    right: 0.5rem;
    top: 0;
    margin-left: auto;
}

.cross svg path {
    fill: white;
}

.navigation-modal {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
}

.navigation-modal li {
    font-size: 30px;
    list-style-type: none;
    color: white;
    letter-spacing: 0.05rem;
    text-align: center;
}

.navigation-modal a {
    text-decoration: none;
}

.navigation-modal a li:active {
    color: rgb(245, 245, 245);
}

.close-nav-button {
    background-color: #0000;
    border: none;
}
